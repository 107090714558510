import React from 'react'
import "./houseinfo.css"

function HouseInfo() {
  return (
    <div className='houseinfo'>
        <div className='house-data'>
            <h1>Više o kući</h1>
            <p>Kuća Emma okružena je fascinantnim prirodnim ljepotama, omogućavajući vam da uživate u prirodi i životu bilo sami, s prijateljima ili obitelji... U dobrom društvu i okružju netaknute prirode zaboravit ćete na sve svakodnevne brige i opustiti se kao nikad dosad.</p>
            <h2>Smještaj</h2>
            <p>Kuća je savršena za obitelji s djecom. Dok roditelji uživaju u roštilju, djeca se mogu igrati nogometa ili na prostoru za odbojku, igrati badminton ili graditi pijeskom ili mnoge druge stvari...</p>
            <h2>Dostupno gostima</h2>
            <p>Gostima je dostupna cijela kuća, bez restrikcija! Savršeno bi bilo kada biste instalirali aplikaciju Vision One na mobitel ili tablet. Time si olakšavate pronalazak atraktivnih lokacija u okolici.</p>
            <h2>Ostale napomene</h2>
            <p>Jedna je kamera na posjedu, ali ona ne snima goste unutra ili prati njihove radnje. Služi isključivo praćenju posjeda iz sigurnosnih razloga.</p>
            <h1>Dođite, nećete požaliti. Dobrodošli ste bilo kada!</h1>
        </div>
    </div>
  )
}

export default HouseInfo