import React, {useEffect, useState, useRef} from 'react'
import Intro from '../intro/Intro'
import Footer from "../footer/Footer"

import "./contact.css"
import emailjs from '@emailjs/browser';



import img from "../../files/image29.webp"
import { Link } from 'react-router-dom'


import OpenInFullIcon from '@mui/icons-material/OpenInFull';





function Contact() {

  const [toggled, setToggled] = useState(false)

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [inquiry, setInquiry] = useState("")

  const [elementx, setElement] = useState(false)
  const [elementxxx, setElementxxx] = useState(false)


  const form = useRef();



  useEffect(() => {
    window.scroll({top: 0, left: 0, behavior: "smooth"})
  }, [])




  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_7sxu39e', 'YOUR_TEMPLATE_ID', form.current, 'YOUR_USER_ID')
      .then((result) => {
          console.log(result.text);
          setName("");
          setEmail("");
          setPhone("");
          setInquiry("");
          
          setElement(true);
      }, (error) => {
          console.log(error.text);
          setElementxxx(true)
      });
  };

// User ID           user_saWdICV9owpFaiJHB3V1j
// Access Token      5a654873e41fdbc5b9e4ca3521082e18

useEffect(() => {
    /* function nameset(e) {
      setName(input1Ref.current.value)
    }
    function emailset(e) {
      setName(input2Ref.current.value)
    }
    function phoneset(e) {
      setName(input3Ref.current.value)
    }
    function inquiryset(e) {
      setName(input4Ref.current.value)
    }
    */

}, [name, email, phone, inquiry, elementx, elementxxx])


  return (
    <div className='contact'>

{/* NAVIGATION */}

<div className='navigation'>
     <h1 className='nav-img'>Emma</h1>
      <div className='nav-bar'>
        <Link className="nav-link" to="/"><p>Početna</p></Link>
        <Link className="nav-link" to="/galerija"><p>Galerija</p></Link>
        <Link className="nav-link" to="/sadrzaj"><p>Sadržaj</p></Link>
        <Link className="nav-link" to="/cijene"><p>Cijene</p></Link>
        <Link className="nav-link coloured" to="/kontakt"><p>Kontakt</p></Link>
      </div>
      </div>



           {/* NAVIGATION 2 */}

   <div className='navigation2'>
     <h1 className='nav-img2'>Emma</h1>
       <OpenInFullIcon className='switcher' onClick={() => {setToggled(!toggled)}} />
   </div>
   <div className={toggled ? "showed" : "hidden"}>
        <Link className="add" to="/"><p>Početna</p></Link>
        <Link className="add" to="/galerija"><p>Galerija</p></Link>
        <Link className="add" to="/sadrzaj"><p>Sadržaj</p></Link>
        <Link className="add" to="/cijene"><p>Cijene</p></Link>
        <Link className="add" to="/kontakt"><p>Kontakt</p></Link>
   </div>


      <Intro imgsrc={img} text="Kontakt" />


      <div className='cont-middle'>
        <div className='cont-form'>
            <form className='form' onSubmit={sendEmail} ref={form}>
              <h5>Vaše ime i prezime</h5>
              <input type="text" name="name"  onChange={(e) => setName(e.target.value)} required placeholder='utipkajte...'/>
              <h5>Vaš e-mail</h5>
              <input type="email" name="email"  onChange={(e) => setEmail(e.target.value)} required placeholder='utipkajte...'/>
              <h5>Broj vašeg telefona</h5>
              <input type="phone" name="phone"  onChange={(e) => setPhone(e.target.value)} required placeholder='utipkajte...'/>
              <h5>Objasnite svoj upit</h5>
              <textarea required placeholder='utipkajte...' value={inquiry} onChange={(e) => setInquiry(e.target.value)}></textarea>
              <button type='submit'>Pošaljite pitanje</button>
              {elementx && <h2 style={{color:"green"}}>Uspješno poslano!</h2>}
              {elementxxx && <h2 style={{color:"red"}}>Nešto je trenutno zapelo. Pošaljite izravno poruku <a href="mailto:kucazaodmoremma@gmail.com" style={{color:"red"}}>ovdje</a>!</h2>}
            </form>
        </div>
        <div className='cont-data'>
          <h1>Što god vas zanima, pitajte</h1>
          <p>Odgovor obično slijedi jako brzo. Da bismo sve ubrzali, odmah nastojte pružiti sve potrebne podatke - starost osoba koje dolaze, njihov broj i sve druge relevantne informacije.</p>
          <h3>kucazaodmoremma@gmail.com</h3>
          <h3>+385 95 593 7266 (Hrvatska)</h3>
        </div>
      </div>



      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2809.9117080441183!2d15.345166615549617!3d45.22935367909893!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47640b9e989d0dbd%3A0x601ec5aedc07f9f6!2sHoliday%20house%20%22Emma%22!5e0!3m2!1sen!2shr!4v1648225001407!5m2!1sen!2shr" height="350" className='google-maps' allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" />





      <Footer />

    </div>
  )
}

export default Contact