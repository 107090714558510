import React from 'react'
import "./galleryhome.css"

import ScrollContainer from 'react-indiana-drag-scroll'
import { Link } from 'react-router-dom'

import image1 from "../../files/image1.webp"
import image3 from "../../files/image3.webp"
import image5 from "../../files/image5.webp"
import image7 from "../../files/image7.webp"
import image9 from "../../files/image9.webp"
import image11 from "../../files/image11.webp"
import image13 from "../../files/image13.webp"
import image15 from "../../files/image15.webp"
import image17 from "../../files/image17.jpeg"
import image19 from "../../files/image19.jpeg"




function GalleryHome() {
  return (
    <div className='galleryhome'>
        <h1 className='gh-h1'>Galerija sa skrolanjem</h1>
        <ScrollContainer className="gh-scroll" horizontal="true" vertical="false">
        <img src={image1} alt="Vacation house Emma in Croatia, region of Lika, with beautiful swimming pool and resting place" className='gh-img' draggable="false" />
        <img src={image3} alt="Vacation house Emma in Croatia, region of Lika, with beautiful swimming pool and resting place" className='gh-img' draggable="false" />
        <img src={image5} alt="Vacation house Emma in Croatia, region of Lika, with beautiful swimming pool and resting place" className='gh-img' draggable="false" />
        <img src={image7} alt="Vacation house Emma in Croatia, region of Lika, with beautiful swimming pool and resting place" className='gh-img' draggable="false" />
        <img src={image9} alt="Vacation house Emma in Croatia, region of Lika, with beautiful swimming pool and resting place" className='gh-img' draggable="false" />
        <img src={image11} alt="Vacation house Emma in Croatia, region of Lika, with beautiful swimming pool and resting place" className='gh-img' draggable="false" />
        <img src={image13} alt="Vacation house Emma in Croatia, region of Lika, with beautiful swimming pool and resting place" className='gh-img' draggable="false" />
        <img src={image15} alt="Vacation house Emma in Croatia, region of Lika, with beautiful swimming pool and resting place" className='gh-img' draggable="false" />
        <img src={image17} alt="Vacation house Emma in Croatia, region of Lika, with beautiful swimming pool and resting place" className='gh-img' draggable="false" />
        <img src={image19} alt="Vacation house Emma in Croatia, region of Lika, with beautiful swimming pool and resting place" className='gh-img' draggable="false" />
      </ScrollContainer>
        <Link to="/galerija" className="gh-link">Vidite sve fotografije</Link>
    </div>
  )
}

export default GalleryHome