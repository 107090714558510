import React, {useEffect, useState} from 'react'
import Intro from '../intro/Intro'
import Footer from "../footer/Footer"

import "./prices.css"

import img from "../../files/image26.webp"
import { Link } from 'react-router-dom'


import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import EuroSymbolIcon from '@mui/icons-material/EuroSymbol';


function Prices() {

  const [toggled, setToggled] = useState(false)


  useEffect(() => {
    window.scroll({top: 0, left: 0, behavior: "smooth"})
  }, [])


  return (
    <div className='prices'>

      {/* NAVIGATION */}

      <div className='navigation'>
     <h1 className='nav-img'>Emma</h1>
      <div className='nav-bar'>
        <Link className="nav-link" to="/"><p>Početna</p></Link>
        <Link className="nav-link" to="/galerija"><p>Galerija</p></Link>
        <Link className="nav-link" to="/sadrzaj"><p>Sadržaj</p></Link>
        <Link className="nav-link" to="/cijene"><p>Cijene</p></Link>
        <Link className="nav-link coloured" to="/kontakt"><p>Kontakt</p></Link>
      </div>
      </div>



           {/* NAVIGATION 2 */}

   <div className='navigation2'>
     <h1 className='nav-img2'>Emma</h1>
       <OpenInFullIcon className='switcher' onClick={() => {setToggled(!toggled)}} />
   </div>
   <div className={toggled ? "showed" : "hidden"}>
        <Link className="add" to="/"><p>Početna</p></Link>
        <Link className="add" to="/galerija"><p>Galerija</p></Link>
        <Link className="add" to="/sadrzaj"><p>Sadržaj</p></Link>
        <Link className="add" to="/cijene"><p>Cijene</p></Link>
        <Link className="add" to="/kontakt"><p>Kontakt</p></Link>
   </div>


                        <Intro imgsrc={img} text="Cijene" />



<div className='price-info'>
  <EuroSymbolIcon className='euro' />
  <p>Cijenu usluge određuje niz čimbenika. Prije svega starosna dob osobe, je li odrasla, dijete od dvije do 12 godina starosti ili mlađe od dvije godine. Dodatno, potrebna je provjera s vlasnikom objekta u slučaju dovođenja dva ili više ljubimca i hoće li se to odraziti na cijenu. Konačno, bitno je provjeriti je li smještaj dostupan u određenom dijelu godine. Stoga odmah pošaljite upit da biste sve provjerili klikom <Link to="/kontakt" className='eurolink'>ovdje</Link>.</p>
</div>


                        <Footer />


    </div>
  )
}

export default Prices