import React from 'react'
import "./basicinfo.css"

import basicimg from "../../files/image11.webp"
import tajana from "../../files/tajana.png"
import { Link } from 'react-router-dom'



function BasicInfo() {
  return (
    <div className='basicinfo'>
        <div className='basic1'>
            
                <img src={tajana} alt="Tajana Stipetić, the owner of vacation house in Tounj" className='tajana' draggable="false" />
                <p className='tajana-desc'>Bok. Moje je ime Tajana Stipetić i iznajmljujem vam kuću za odmor! Moj je cilj da se osjećate točno onako kao da ste u svojem domu. Uživajte u netaknutoj prirodi. Kad se sretnemo, upoznat ćemo se puno detaljnije! Bilo bi mi drago da se vidimo što je prije moguće!</p>
                <div className='basic-line'></div>
                <div className='basic-more'>  
                <p><b>Jezik:</b> engleski</p>
                <p><b>Postotak odgovora na upite:</b> 100 %</p>
                <p><b>Uobičajeno čekanje na odgovor:</b> manje od 1 h</p>
                <p><b>Dostupnost tijekom boravka:</b> stalno</p>
                </div>
                <div className='basic-line'></div>
                <Link to="/kontakt" className="basic-btn">Pitajte me bilo što</Link>
                </div>
                
        <img src={basicimg} alt="Vacation house Emma in Croatian town Tounj, beautiful and calm place in the middle of the nature - forests and mountains of region Lika with fresh air and pleasant environment" className='basic2' draggable="false" />
 
    </div>
  )
}

export default BasicInfo