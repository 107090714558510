import './App.css';
import {BrowserRouter as Router, Routes, Route} from "react-router-dom"

import CookieConsent from "react-cookie-consent";

import Home from "./components/ahome/Home"
import Gallery from "./components/agallery/Gallery"
import Content from "./components/acontent/Content"
import Prices from "./components/aprices/Prices"
import Contact from "./components/acontact/Contact"
import Error from "./components/aerror/Error"

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="/galerija" exact element={<Gallery />} />
            <Route path="/sadrzaj" exact element={<Content />} />
            <Route path="/cijene" exact element={<Prices />} />
            <Route path="/kontakt" exact element={<Contact />} />
            <Route path="/*" exact element={<Error />} />

        </Routes>

        <CookieConsent
location="bottom"
buttonText="U redu"
cookieName="myAwesomeCookieName2"
style={{ background: "white", color: "#da0b64", fontWeight: "bold", boxShadow: "gray 0px 0px 38px 4px", fontSize: "11pt", }}
buttonStyle={{ background: "#da0b64", color: "white", fontSize: "12pt", borderRadius: "20px", border: "white solid 3px" }}
expires={150}
>
Možete ukloniti ovu obavijest potvrdom uz ovaj tekst. Kolačići su malene datoteke koje ni na koji način ne otkrivaju nečiji identitet. Njihova je svrha osigurati ugodnije korisničko iskustvo prilikom posjećivanja ovog mjesta. Uživajte!
</CookieConsent>
      </Router>
    </div>
  );
}

export default App;
