import React from 'react'
import { Link } from 'react-router-dom'

import "./error.css"


function Error() {
  return (
    <div className='error'>
      <h1 className='error-h1'>Bok, na stranicama ste Kuće za odmor "Emma" u Tounju, Hrvatska. Izgleda da ovdje nema ono što vjerojatno tražite. Molimo da provjerite jeste li ispravno upisali tekst poveznice u svoj pretraživač ili jednostavno odite na <Link to="/" className='error-link'>početnu stranicu</Link> i nastavite surfati.</h1>
    </div>
  )
}

export default Error