import React from 'react'
import "./footer.css"

import foot1 from "../../files/favicon.png"
import foot2 from "../../files/airbnb.webp"

import FacebookIcon from '@mui/icons-material/Facebook';
import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

function Footer() {

    let myDate = new Date()

let someDay = myDate.getDate();
let someMonth = myDate.getMonth() + 1;
let someYear = myDate.getFullYear();



  return (
  <div>  
    <div className='footer'>
    <img src={foot1} alt="Logo of the vacation house Emma in Croatian county Lika, mountain and forest region with beautiful landscape where you can enjoy the life, far away from cities, rush and noise" className='foot-img one' draggable="false" />
    <img src={foot2} alt="Logo of the platform Airbnb, where you can find all needed data about vacation house Emma, Tounj, Croatia" className='foot-img two' draggable="false" />
    <h2 className='foot-h three'>
    Kukača 114b, 47264 Tounj, Republika Hrvatska <br />(+385 95 593 7266)
    </h2>
    <a href="https://api.whatsapp.com/send?phone=385955937266"><WhatsAppIcon className='foot-icon four'/></a>

    <a href="tel:+385955937266"><AddIcCallIcon className='foot-icon five'/></a>
    <a href="mailto:kucazaodmoremma@gmail.com"><EmailIcon className='foot-icon six'/></a>
    <a href="https://www.facebook.com/kucazaodmoremm" target="_blank" rel="noreferrer"><FacebookIcon className='foot-icon seven'/></a>
    
    
    
    
    
    </div>
    
    <div className='sign'> 
    { `${someDay}. ${someMonth}. ${someYear}.` } · Sva prava pridržana · Izradio <a href='https://mathscript.eu' target="_blank" rel="noreferrer">Mathscript</a>
    </div>

    </div>
  )
}

export default Footer 