import React from 'react'
import "./intro.css"

function Intro(props) {
  return (
    <div className='intro'>
         <div className='intro1'>
            <img src={props.imgsrc} alt="Vacation house Emma in Tounj, Croatia, chosen view from house internal space" className='introimg' draggable="false" />
        </div>
        <div className='intro2'>
            <h1>{props.text}</h1>
        </div>
    </div>
  )
}

export default Intro