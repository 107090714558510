import React from 'react'
import "./reviews.css"

import ScrollContainer from 'react-indiana-drag-scroll'

import image1 from "../../files/review1.png"
import image3 from "../../files/review3.png"
import image2 from "../../files/review2.png"
import image4 from "../../files/review4.png"
import image5 from "../../files/review5.png"
import image6 from "../../files/review6.png"

import StarIcon from '@mui/icons-material/Star';

function Reviews() {
  return (
    <div className='reviews'>
         <h1 className='rev-h1'>Recenzije s Airbnba s pomicanjem (<StarIcon /><StarIcon /><StarIcon /><StarIcon /><StarIcon />  4.94)</h1>
        <ScrollContainer className="rev-scroll" horizontal="true" vertical="false">
        <img src={image1} alt="Vacation house Emma in Croatia, region of Lika, with beautiful swimming pool and resting. Chosen reviews from customers on platform Airbnb with almost all positive reactions, proving high quality of tourism service in Croatia, not just sea tourism, but village tourism and trips related with environment and nature." className='rev-img' draggable="false" />
        <img src={image2} alt="Vacation house Emma in Croatia, region of Lika, with beautiful swimming pool and resting. Chosen reviews from customers on platform Airbnb with almost all positive reactions, proving high quality of tourism service in Croatia, not just sea tourism, but village tourism and trips related with environment and nature." className='rev-img' draggable="false" />
        <img src={image3} alt="Vacation house Emma in Croatia, region of Lika, with beautiful swimming pool and resting. Chosen reviews from customers on platform Airbnb with almost all positive reactions, proving high quality of tourism service in Croatia, not just sea tourism, but village tourism and trips related with environment and nature." className='rev-img' draggable="false" />
        <img src={image4} alt="Vacation house Emma in Croatia, region of Lika, with beautiful swimming pool and resting. Chosen reviews from customers on platform Airbnb with almost all positive reactions, proving high quality of tourism service in Croatia, not just sea tourism, but village tourism and trips related with environment and nature." className='rev-img' draggable="false" />
        <img src={image5} alt="Vacation house Emma in Croatia, region of Lika, with beautiful swimming pool and resting. Chosen reviews from customers on platform Airbnb with almost all positive reactions, proving high quality of tourism service in Croatia, not just sea tourism, but village tourism and trips related with environment and nature." className='rev-img' draggable="false" />
        <img src={image6} alt="Vacation house Emma in Croatia, region of Lika, with beautiful swimming pool and resting. Chosen reviews from customers on platform Airbnb with almost all positive reactions, proving high quality of tourism service in Croatia, not just sea tourism, but village tourism and trips related with environment and nature." className='rev-img' draggable="false" />
      
      </ScrollContainer>
        <a href="https://hr.airbnb.com/rooms/16157898/reviews?pets=5&infants=2&children=1&check_in=2022-03-27&adults=2&guests=3&s=39&unique_share_id=1a1a1aee-7c8b-48e2-bd63-7729f00d80b4&_branch_match_id=1035270038311129323&_branch_referrer=H4sIAAAAAAAAA8soKSkottLXT0zKS9LLTdWvSvH0KjLy8C%2FPTwIA6w1MCxsAAAA%3D&source_impression_id=p3_1648065839_HBPi2jPm7%2BCx5LXm&check_out=2022-03-31" target="_blank" rel="noreferrer" className="rev-link">Sve recenzije na Airbnbu</a>
    </div>
  )
}

export default Reviews