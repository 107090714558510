import React, {useEffect} from 'react'
import Header from '../header/Header'
import BasicInfo from "../basicinfo/BasicInfo"
import GalleryHome from "../galleryhome/GalleryHome"
import Reviews from '../reviews/Reviews'
import HouseInfo from "../houseinfo/HouseInfo"
import Footer from "../footer/Footer"

function Home() {

  
  useEffect(() => {
    window.scroll({top: 0, left: 0, behavior: "smooth"})
  }, [])


  return (
    <div>
      <Header />
      <BasicInfo />
      <GalleryHome />
      <Reviews />
      <HouseInfo />
      <Footer />
    </div>
  )
}

export default Home