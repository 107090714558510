import React, {useState} from 'react'
import "./header.css"
import { Link } from 'react-router-dom'

import image5 from "../../files/image5.webp"


import OpenInFullIcon from '@mui/icons-material/OpenInFull';


function Header() {

  const [toggled, setToggled] = useState(false)

  return ( 
    <div className='header'>
      <img src={image5} alt="Vacation house Emma in Croatia, region of Lika, with beautiful swimming pool and resting place" className='header-img' draggable="false" />
      <h1 className='header-h1'>Kuća za odmor Emma u Tounju, Hrvatska</h1>
      <div className='header-ul'>
        <div className='head-col-1'>8 gostiju</div>
        <div className='head-col-2'>4 spavaće</div>
        <div className='head-col-3'>6 kreveta</div>
        <div className='head-col-4'>1.5 kupaonica</div>
      </div>
 
      {/* NAVIGATION */}

      <div className='navigation'>
     <h1 className='nav-img'>Emma</h1>
      <div className='nav-bar'>
        <Link className="nav-link" to="/"><p>Početna</p></Link>
        <Link className="nav-link" to="/galerija"><p>Galerija</p></Link>
        <Link className="nav-link" to="/sadrzaj"><p>Sadržaj</p></Link>
        <Link className="nav-link" to="/cijene"><p>Cijene</p></Link>
        <Link className="nav-link coloured" to="/kontakt"><p>Kontakt</p></Link>
      </div>
      </div>



           {/* NAVIGATION 2 */}

   <div className='navigation2'>
     <h1 className='nav-img2'>Emma</h1>
       <OpenInFullIcon className='switcher' onClick={() => {setToggled(!toggled)}} />
   </div>
   <div className={toggled ? "showed" : "hidden"}>
        <Link className="add" to="/"><p>Početna</p></Link>
        <Link className="add" to="/galerija"><p>Galerija</p></Link>
        <Link className="add" to="/sadrzaj"><p>Sadržaj</p></Link>
        <Link className="add" to="/cijene"><p>Cijene</p></Link>
        <Link className="add" to="/kontakt"><p>Kontakt</p></Link>
   </div>



   </div>
  )
}

export default Header