import React, {useEffect, useState} from 'react'
import Intro from '../intro/Intro'
import Footer from "../footer/Footer"

import "./gallery.css"

import img from "../../files/image19.jpeg"

import img1 from "../../files/image1.webp"
import img2 from "../../files/image2.webp"
import img3 from "../../files/image3.webp"
import img4 from "../../files/image4.webp"
import img5 from "../../files/image5.webp"
import img6 from "../../files/image6.webp"
import img7 from "../../files/image7.webp"
import img8 from "../../files/image8.jpeg"
import img9 from "../../files/image9.webp"
import img10 from "../../files/image10.webp"
import img11 from "../../files/image11.webp"
import img12 from "../../files/image12.webp"
import img13 from "../../files/image13.webp"
import img14 from "../../files/image14.webp"
import img15 from "../../files/image15.webp"
import img16 from "../../files/image16.webp"
import img17 from "../../files/image17.jpeg"
import img18 from "../../files/image18.webp"
import img20 from "../../files/image20.webp"
import img21 from "../../files/image21.webp"
import img22 from "../../files/image22.webp"
import img23 from "../../files/image23.webp"
import img24 from "../../files/image24.webp"
import img25 from "../../files/image25.jpeg"
import img26 from "../../files/image26.webp"
import img27 from "../../files/image27.jpeg"
import img28 from "../../files/image28.webp"
import img29 from "../../files/image29.webp"



import { Link } from 'react-router-dom'

import OpenInFullIcon from '@mui/icons-material/OpenInFull';



function Gallery() {

  const [toggled, setToggled] = useState(false)

  // spread-classes
  const [spreadx1, setSpreadx1] = useState(false)
  const [spreadx2, setSpreadx2] = useState(false)
  const [spreadx3, setSpreadx3] = useState(false)
  const [spreadx4, setSpreadx4] = useState(false)
  const [spreadx5, setSpreadx5] = useState(false)
  const [spreadx6, setSpreadx6] = useState(false)
  const [spreadx7, setSpreadx7] = useState(false)
  const [spreadx8, setSpreadx8] = useState(false)
  const [spreadx9, setSpreadx9] = useState(false)
  const [spreadx10, setSpreadx10] = useState(false)
  const [spreadx11, setSpreadx11] = useState(false)
  const [spreadx12, setSpreadx12] = useState(false)
  const [spreadx13, setSpreadx13] = useState(false)
  const [spreadx14, setSpreadx14] = useState(false)
  const [spreadx15, setSpreadx15] = useState(false)
  const [spreadx16, setSpreadx16] = useState(false)
  const [spreadx17, setSpreadx17] = useState(false)
  const [spreadx18, setSpreadx18] = useState(false)
  const [spreadx19, setSpreadx19] = useState(false)
  const [spreadx20, setSpreadx20] = useState(false)
  const [spreadx21, setSpreadx21] = useState(false)
  const [spreadx22, setSpreadx22] = useState(false)
  const [spreadx23, setSpreadx23] = useState(false)
  const [spreadx24, setSpreadx24] = useState(false)
  const [spreadx25, setSpreadx25] = useState(false)
  const [spreadx26, setSpreadx26] = useState(false)
  const [spreadx27, setSpreadx27] = useState(false)
  const [spreadx28, setSpreadx28] = useState(false)
  const [spreadx29, setSpreadx29] = useState(false)





  useEffect(() => {
    window.scroll({top: 0, left: 0, behavior: "smooth"})
  }, [])


  return (
    <div className='gallery'>


      {/* NAVIGATION */}

      <div className='navigation'>
     <h1 className='nav-img'>Emma</h1>
      <div className='nav-bar'>
        <Link className="nav-link" to="/"><p>Početna</p></Link>
        <Link className="nav-link" to="/galerija"><p>Galerija</p></Link>
        <Link className="nav-link" to="/sadrzaj"><p>Sadržaj</p></Link>
        <Link className="nav-link" to="/cijene"><p>Cijene</p></Link>
        <Link className="nav-link coloured" to="/kontakt"><p>Kontakt</p></Link>
      </div>
      </div>



           {/* NAVIGATION 2 */}

   <div className='navigation2'>
     <h1 className='nav-img2'>Emma</h1>
       <OpenInFullIcon className='switcher' onClick={() => {setToggled(!toggled)}} />
   </div>
   <div className={toggled ? "showed" : "hidden"}>
        <Link className="add" to="/"><p>Početna</p></Link>
        <Link className="add" to="/galerija"><p>Galerija</p></Link>
        <Link className="add" to="/sadrzaj"><p>Sadržaj</p></Link>
        <Link className="add" to="/cijene"><p>Cijene</p></Link>
        <Link className="add" to="/kontakt"><p>Kontakt</p></Link>
   </div>
        <Intro imgsrc={img} text="Galerija" />



      <div className='carousel'>
      <img src={img1} alt="Vacation house Emma in Tounj, Croatia, chosen view from house internal space" className={spreadx1 ? "carousel-img spread" : "carousel-img"} draggable="false" onClick={() => {setSpreadx1(!spreadx1)}}/>
      <img src={img2} alt="Vacation house Emma in Tounj, Croatia, chosen view from house internal space" className={spreadx2 ? "carousel-img spread" : "carousel-img"} draggable="false" onClick={() => {setSpreadx2(!spreadx2)}} />
      <img src={img3} alt="Vacation house Emma in Tounj, Croatia, chosen view from house internal space" className={spreadx3 ? "carousel-img spread" : "carousel-img"} draggable="false" onClick={() => {setSpreadx3(!spreadx3)}} />
      <img src={img4} alt="Vacation house Emma in Tounj, Croatia, chosen view from house internal space" className={spreadx4 ? "carousel-img spread" : "carousel-img"} draggable="false" onClick={() => {setSpreadx4(!spreadx4)}} />
      <img src={img5} alt="Vacation house Emma in Tounj, Croatia, chosen view from house internal space" className={spreadx5 ? "carousel-img spread" : "carousel-img"} draggable="false" onClick={() => {setSpreadx5(!spreadx5)}} />
      <img src={img6} alt="Vacation house Emma in Tounj, Croatia, chosen view from house internal space" className={spreadx6 ? "carousel-img spread" : "carousel-img"} draggable="false" onClick={() => {setSpreadx6(!spreadx6)}} />
      <img src={img7} alt="Vacation house Emma in Tounj, Croatia, chosen view from house internal space" className={spreadx7 ? "carousel-img spread" : "carousel-img"} draggable="false" onClick={() => {setSpreadx7(!spreadx7)}} />
      <img src={img8} alt="Vacation house Emma in Tounj, Croatia, chosen view from house internal space" className={spreadx8 ? "carousel-img spread" : "carousel-img"} draggable="false" onClick={() => {setSpreadx8(!spreadx8)}} />
      <img src={img9} alt="Vacation house Emma in Tounj, Croatia, chosen view from house internal space" className={spreadx9 ? "carousel-img spread" : "carousel-img"} draggable="false" onClick={() => {setSpreadx9(!spreadx9)}} />
      <img src={img10} alt="Vacation house Emma in Tounj, Croatia, chosen view from house internal space" className={spreadx10 ? "carousel-img spread" : "carousel-img"} draggable="false" onClick={() => {setSpreadx10(!spreadx10)}} />
      <img src={img11} alt="Vacation house Emma in Tounj, Croatia, chosen view from house internal space" className={spreadx11 ? "carousel-img spread" : "carousel-img"} draggable="false" onClick={() => {setSpreadx11(!spreadx11)}} />
      <img src={img12} alt="Vacation house Emma in Tounj, Croatia, chosen view from house internal space" className={spreadx12 ? "carousel-img spread" : "carousel-img"} draggable="false" onClick={() => {setSpreadx12(!spreadx12)}} />
      <img src={img13} alt="Vacation house Emma in Tounj, Croatia, chosen view from house internal space" className={spreadx13 ? "carousel-img spread" : "carousel-img"} draggable="false" onClick={() => {setSpreadx13(!spreadx13)}} />
      <img src={img14} alt="Vacation house Emma in Tounj, Croatia, chosen view from house internal space" className={spreadx14 ? "carousel-img spread" : "carousel-img"} draggable="false" onClick={() => {setSpreadx14(!spreadx14)}} />
      <img src={img15} alt="Vacation house Emma in Tounj, Croatia, chosen view from house internal space" className={spreadx15 ? "carousel-img spread" : "carousel-img"} draggable="false" onClick={() => {setSpreadx15(!spreadx15)}} />
      <img src={img16} alt="Vacation house Emma in Tounj, Croatia, chosen view from house internal space" className={spreadx16 ? "carousel-img spread" : "carousel-img"} draggable="false" onClick={() => {setSpreadx16(!spreadx16)}} />
      <img src={img17} alt="Vacation house Emma in Tounj, Croatia, chosen view from house internal space" className={spreadx17 ? "carousel-img spread" : "carousel-img"} draggable="false" onClick={() => {setSpreadx17(!spreadx17)}} />
      <img src={img18} alt="Vacation house Emma in Tounj, Croatia, chosen view from house internal space" className={spreadx18 ? "carousel-img spread" : "carousel-img"} draggable="false" onClick={() => {setSpreadx18(!spreadx18)}} />
      <img src={img22} alt="Vacation house Emma in Tounj, Croatia, chosen view from house internal space" className={spreadx19 ? "carousel-img spread" : "carousel-img"} draggable="false" onClick={() => {setSpreadx19(!spreadx19)}} />
      <img src={img20} alt="Vacation house Emma in Tounj, Croatia, chosen view from house internal space" className={spreadx20 ? "carousel-img spread" : "carousel-img"} draggable="false" onClick={() => {setSpreadx20(!spreadx20)}} />
      <img src={img21} alt="Vacation house Emma in Tounj, Croatia, chosen view from house internal space" className={spreadx21 ? "carousel-img spread" : "carousel-img"} draggable="false" onClick={() => {setSpreadx21(!spreadx21)}} />
      <img src={img23} alt="Vacation house Emma in Tounj, Croatia, chosen view from house internal space" className={spreadx22 ? "carousel-img spread" : "carousel-img"} draggable="false" onClick={() => {setSpreadx22(!spreadx22)}} />
      <img src={img24} alt="Vacation house Emma in Tounj, Croatia, chosen view from house internal space" className={spreadx23 ? "carousel-img spread" : "carousel-img"} draggable="false" onClick={() => {setSpreadx23(!spreadx23)}} />
      <img src={img25} alt="Vacation house Emma in Tounj, Croatia, chosen view from house internal space" className={spreadx24 ? "carousel-img spread" : "carousel-img"} draggable="false" onClick={() => {setSpreadx24(!spreadx24)}} />
      <img src={img26} alt="Vacation house Emma in Tounj, Croatia, chosen view from house internal space" className={spreadx25 ? "carousel-img spread" : "carousel-img"} draggable="false" onClick={() => {setSpreadx25(!spreadx25)}} />
      <img src={img27} alt="Vacation house Emma in Tounj, Croatia, chosen view from house internal space" className={spreadx26 ? "carousel-img spread" : "carousel-img"} draggable="false" onClick={() => {setSpreadx26(!spreadx26)}} />
      <img src={img28} alt="Vacation house Emma in Tounj, Croatia, chosen view from house internal space" className={spreadx27 ? "carousel-img spread" : "carousel-img"} draggable="false" onClick={() => {setSpreadx27(!spreadx27)}} />
      <img src={img29} alt="Vacation house Emma in Tounj, Croatia, chosen view from house internal space" className={spreadx28 ? "carousel-img spread" : "carousel-img"} draggable="false" onClick={() => {setSpreadx28(!spreadx28)}} />
      <img src={img} alt="Vacation house Emma in Tounj, Croatia, chosen view from house internal space" className={spreadx29 ? "carousel-img spread" : "carousel-img"} draggable="false" onClick={() => {setSpreadx29(!spreadx29)}} />
      </div>
            

            <Link to="/kontakt" className='touch'><div className='btn-forw'>Rezervirajte svoje mjesto</div></Link>
          <Footer />
    </div>
  )
}

export default Gallery